import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from "react-leaflet";
import L from "leaflet"
import 'swiper/css';
import 'swiper/css/pagination';
import SimpleImageSlider from "react-simple-image-slider";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {Logo, MiniLogo, SearchIcon} from "./Icons";

const arrayChunk = (inp, chunkSize = 4) => {
    const res = [];
    for (let i = 0; i < inp.length; i += chunkSize) {
        const chunk = inp.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}



function App() {
    const [markers, setMarkers] = useState([])
    const [categories, setCategories] = useState([])
    const [showedCategories, setShowedCategories] = useState([])
    const [hiddenCategories, setHiddenCategories] = useState([])
    const [search, setSearch] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [Locations, setLocations] = useState([])
    const markerRefs = useRef({});

    const zoomToMarker = (position) => {
        // map.setView(position, 13);
        const markerRef = markerRefs.current[JSON.stringify(position)];
        if (markerRef) {
            markerRef.openPopup();
        }
    };


    useEffect(() => {
        let e = [];
        axios.get("categories.json").then((r) => {
            setCategories(r.data)
            setShowedCategories(r.data.map((s) => {
                return s.id;
            }))

            axios.get("markers.json").then((m) => {
                setMarkers(m.data)
                for (let s of m.data.reverse()) {
                    e.push( {
                        id: s.id,
                        name: s.title,
                        image: "/images/casino-cover.png",
                        position: { x: 60, y: 39.5 },
                        badge: s.isNew ? "new" : "",
                        parent: s.category
                    })
                }

                for (let c of r.data) {
                    e.push({
                        id: c.id,
                        name: c.name,
                        image: "/images/casino-cover.png",
                        badge: e.filter((v) => v.parent === c.id).length.toString(),
                        type: "group",
                    });
                }

                setLocations(e.reverse())
            })
        })
    }, []);

    const toggleCategory = (name) => {
        if (showedCategories.includes(name)) {
            setShowedCategories(showedCategories.filter((s) => s !== name))
            let a = hiddenCategories;
            a.push(name);
            setHiddenCategories(a)
        } else {
            setHiddenCategories(hiddenCategories.filter((s) => s !== name))
            let a = showedCategories;
            a.push(name);
            setShowedCategories(a)
        }
    }
    const findParentLocation = (locationId) => {
        return Locations.find((loc) => loc.id === locationId);
    };
    const filteredLocations = Locations.filter((location) => {
        const isMatch = location.name
            .toLowerCase()
            .includes(search.toLowerCase());
        const isParentMatch =
            location.parent &&
            findParentLocation(location.parent)
                ?.name.toLowerCase()
                .includes(search.toLowerCase());
        return isMatch || isParentMatch;
    });

    const locationsWithParents = filteredLocations.reduce(
        (acc, location) => {
            if (location.parent) {
                const parentLocation = findParentLocation(location.parent);
                if (
                    parentLocation &&
                    !acc.some((loc) => loc.id === parentLocation.id)
                ) {
                    acc.push(parentLocation);
                }
            }
            acc.push(location);
            return acc;
        },
        []
    );

    return <div className="main">
        <div className="sidebar">
            <div className="header">
                <div className="logo">
                    <Logo/>
                    KingMaps
                </div>
            </div>
            <div className="locations">
                <div className="search-box">
                    <input
                        type="text"
                        placeholder="Search for location ..."
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                    />
                    <SearchIcon/>
                </div>
                {locationsWithParents.map(
                    (location, index) =>
                        !location.parent && (
                            <div
                                key={index}
                                className={`location${
                                    location.type === "group" ? " sub" : ""
                                }${
                                    selectedLocation === location.id ||
                                    selectedGroup === location.id
                                        ? " active"
                                        : ""
                                }`}
                                onClick={(e) => {
                                    if (location.type === "group") {
                                        if (selectedGroup === location.id) {
                                            if (
                                                [
                                                    ...e.target.classList,
                                                ].some((name) =>
                                                    [
                                                        "sub",
                                                        "badge",
                                                    ].includes(name)
                                                )
                                            ) {
                                                setSelectedGroup("");
                                            }
                                        } else {
                                            setSelectedGroup(location.id);
                                        }
                                    } else {
                                        setSelectedGroup("");
                                        // handleZoomToElement(location.id);
                                    }
                                }}
                            >
                                {location.name}
                                {location.badge && (
                                    <span className="badge">
                                            {location.badge}
                                        </span>
                                )}
                                {selectedGroup === location.id &&
                                    location.type === "group" && (
                                        <div className="content">
                                            {Locations.filter(
                                                (item) =>
                                                    item.parent ===
                                                    location.id
                                            )
                                                .filter((item) =>
                                                    item.name
                                                        .toLowerCase()
                                                        .includes(
                                                            search.toLowerCase()
                                                        )
                                                )
                                                .map((location, index) => (
                                                    <div
                                                        key={index}
                                                        className={`location${
                                                            location.type ===
                                                            "group"
                                                                ? " sub"
                                                                : ""
                                                        }${
                                                            selectedLocation ===
                                                            location.id
                                                                ? " active"
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            setSelectedLocation(location.id)
                                                            zoomToMarker(markers.find(m => m.id === location.id).position)
                                                            // handleZoomToElement(
                                                            //     location.id
                                                            // );
                                                        }}
                                                    >
                                                        {location.name}
                                                        {location.badge && (
                                                            <span className="badge">
                                                                    {
                                                                        location.badge
                                                                    }
                                                                </span>
                                                        )}
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                            </div>
                        )
                )}
            </div>
            <p className="text">
                <MiniLogo/>
                KingMaps Interactive Map
            </p>
        </div>

        <MapContainer center={[0, 0]} zoom={4} minZoom={3} maxZoom={5} scrollWheelZoom={true} worldCopyJump={true}
                      style={{
                          width: "100vw",
                          height: "100vh"
                      }} >
            <TileLayer
                url="map/{z}/{x}/{y}.jpg"
            />

            {

                showedCategories.map((name) => (
                    markers.filter((s) => s.category === name).map((marker) => (
                            <Marker position={marker.position} icon={L.icon({
                                iconUrl: marker.icon
                            })}  ref={(el) => {
                                markerRefs.current[JSON.stringify(marker.position)] = el;
                            }}   eventHandlers={{
                                click: () => {
                                    setSelectedLocation(marker.id)
                                    console.log(marker)
                                    if (marker.category) {
                                        setSelectedGroup(marker.category)
                                    }
                                    // setSelectedGroup()
                                }
                            }}>
                                <Popup>
                                    <div className="popup-container">
                                        <SimpleImageSlider
                                            width={600}
                                            height={400}
                                            images={marker.images.map((i) => {
                                                return {url: i}
                                            })}
                                            showBullets={true}
                                            showNavs={true}
                                        />

                                        <a href={marker.link}>
                                            <h1>{marker.title}</h1>
                                        </a>
                                    </div>

                                </Popup>
                            </Marker>


                    ))
                ))
            }

        </MapContainer>
    </div>
}


export default App;
